/* src/App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #000;
  color: #fff;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-overflow-scrolling: touch; /* Smooth momentum scrolling */
  overscroll-behavior: none; 
}

.action-button,
.topic-button,
.politician-button,
.menu-button,
.close-menu-button,
.start-button {
  min-height: 44px; /* Standard touch target size */
  touch-action: manipulation; /* Optimize touch handling */
}

.messages-container,
.main-content,
.side-panel {
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.app-container {
  display: flex;
  position: relative;
  height: 100vh; /* Ensure the container takes full viewport height */
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 64px; /* Adjust based on header height */
  padding-bottom: 80px; /* Adjust based on footer height */
  min-height: 100vh;
  width: 100%;
  overflow-y: auto; /* Enable vertical scrolling */
}

.app-header {
  position: fixed; /* Changed from sticky to fixed */
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-bottom: 1px solid #444;
  padding: 16px;
  text-align: center;
  z-index: 1000; /* Increased z-index to stay above other elements */
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-header h1 {
  font-size: 1.5rem;
  margin: 0;
}

.menu-button {
  display: block; /* Always display the menu button */
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1001; /* Ensure it's above the header */
}

.close-menu-button {
  display: block; /* Always display the close button */
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1001;
}

.input-section {
  padding: 162px 16px 20px;
  border-bottom: 1px solid #444;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.input-section .no-posts-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  color: #fff;
}

.input-section .no-posts-text {
  color: #888;
  margin-bottom: 20px;
  font-size: 15px;
  max-width: 400px;
  line-height: 1.5;
}

.input-section input {
  width: 100%;
  max-width: 400px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #444;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  padding: 14px;
  margin-bottom: 16px;
  outline: none;
  transition: all 0.2s ease;
  -webkit-appearance: none;
}

.input-section input:focus {
  border-color: #1da1f2;
  background: rgba(255, 255, 255, 0.1);
}

.start-button {
  background-color: #1da1f2;
  border: none;
  color: #fff;
  padding: 14px 32px;
  border-radius: 24px;
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  transition: all 0.2s ease;
  width: 180px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

.start-button:active {
  transform: scale(0.98);
  background-color: #1991db;
}

.start-button:hover {
  background-color: #1991db;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(29, 161, 242, 0.3);
}

.start-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.post {
  border-bottom: 1px solid #444;
  padding: 16px;
  position: relative;
  animation: fadeIn 0.3s ease-out;
}

.reactions {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #222;
  padding: 10px;
  border-radius: 20px;
  display: flex;
  gap: 10px;
}

.reactions button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.post-content {
  display: flex;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  flex-shrink: 0;
}

.post-body {
  flex: 1;
  min-width: 0;
}

.post-header {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  flex-wrap: wrap;
  gap: 4px;
}

.post-name {
  font-weight: bold;
  font-size: 15px;
}

.verified {
  color: #1da1f2;
  margin-left: 4px;
}

.post-handle,
.post-time {
  color: #888;
  margin-left: 4px;
  font-size: 14px;
}

.replying-to {
  color: #888;
  font-size: 14px;
  margin-bottom: 4px;
}

.post-text {
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 1.4;
  word-wrap: break-word;
}

.post-actions {
  display: flex;
  justify-content: space-between;
  max-width: 400px;
  gap: 8px;
}

.action-button {
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 8px;
  border-radius: 50%;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

.action-button:active {
  background-color: rgba(29, 161, 242, 0.1);
}

.action-button.active,
.action-button:hover {
  color: #1da1f2;
}

.loading-indicator {
  text-align: center;
  padding: 20px;
  color: #888;
}

.no-posts {
  text-align: center;
  padding: 40px 20px;
  color: #888;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.no-posts-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #fff;
}

.no-posts-text {
  max-width: 400px;
  line-height: 1.5;
}

.overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
}

.overlay.visible {
  display: block;
}

.side-panel {
  width: 300px;
  padding: 20px;
  border-left: 1px solid #444;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: transform 0.3s ease;
  z-index: 1000;
  transform: translateX(100%); /* Hide by default */
}

.side-panel.visible {
  transform: translateX(0); /* Show when visible */
}

.side-panel::-webkit-scrollbar {
  width: 8px;
}

.side-panel::-webkit-scrollbar-track {
  background: transparent;
}

.side-panel::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 4px;
}

.side-panel::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.side-panel.loading {
  pointer-events: none;
  opacity: 0.7;
}

.trending-topics,
.active-politicians {
  background-color: #111;
  padding: 16px;
  border-radius: 12px;
  margin-bottom: 16px;
}

.trending-topics h2,
.active-politicians h2 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 18px;
  color: #fff;
  text-align: center;
}

.politician-buttons,
.topic-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.politician-button,
.topic-button {
  background-color: #333;
  color: #ccc;
  border: none;
  padding: 10px 16px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 14px;
  -webkit-tap-highlight-color: transparent;
  touch-action: manipulation;
}

.politician-button:active,
.topic-button:active {
  background-color: #444;
  transform: scale(0.98);
}

.politician-button.selected,
.topic-button.selected {
  background-color: #1da1f2;
  color: #fff;
}

.politician-button.disabled,
.topic-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  transform: none;
  transition: none;
}

.selection-error {
  background-color: rgba(255, 0, 0, 0.1);
  color: #ff4444;
  padding: 10px;
  border-radius: 8px;
  margin: 10px 0;
  font-size: 14px;
  text-align: center;
}

.selection-guide {
  color: #888;
  text-align: center;
  margin: 10px 0;
  font-size: 14px;
  height: 20px;
}

.guide-text {
  animation: fadeIn 0.3s ease;
}

.battle-status {
  background: rgba(29, 161, 242, 0.1);
  border: 1px solid rgba(29, 161, 242, 0.2);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

.battle-progress {
  font-size: 18px;
  font-weight: bold;
  color: #1da1f2;
  margin-bottom: 5px;
}

.battle-intensity {
  font-size: 14px;
  color: #888;
  margin-top: 5px;
}

.battle-complete-status {
  background: rgba(29, 161, 242, 0.1);
  border: 1px solid rgba(29, 161, 242, 0.2);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

.battle-complete-message {
  font-size: 18px;
  font-weight: bold;
  color: #1da1f2;
  margin-bottom: 5px;
}

.battle-complete-hint {
  font-size: 14px;
  color: #888;
  margin-top: 5px;
}

/* Chat specific styles */
.chat-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 64px; /* Header height */
  bottom: 80px; /* Input container height */
  left: 0;
  right: 0;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 16px;
  -webkit-overflow-scrolling: touch;
  position: relative;
  width: 100%;
  padding-bottom: 24px; /* Add extra padding at bottom */
}

.messages-container::-webkit-scrollbar {
  width: 8px;
}

.messages-container::-webkit-scrollbar-track {
  background: transparent;
}

.messages-container::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 4px;
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-input-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 800px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-top: 1px solid #444;
  padding: 12px 16px;
  z-index: 1000;
  height: 80px;
}

.chat-input-container.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 800px; /* Ensure it does not exceed chat container width */
  margin: 0 auto; /* Center the input container */
  padding-top: 16px; /* Optional: Additional padding if needed */
}

.chat-input-container input {
  flex: 1;
  margin: 0;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid #444;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  padding: 12px;
  outline: none;
  transition: all 0.2s ease;
  -webkit-appearance: none;
  max-width: 100%; /* Ensure input does not exceed its container */
}

.chat-input-container input:focus {
  border-color: #1da1f2;
  background: rgba(255, 255, 255, 0.1);
}

.chat-message {
  display: flex;
  max-width: 85%;
  margin-bottom: 16px; /* Add space between messages */
  padding: 4px; /* Add slight padding around message */
}

.chat-message.user-message {
  margin-left: auto;
  flex-direction: row-reverse;
}

.chat-message.politician-message {
  margin-right: auto;
}

.chat-message .post-content {
  background: #1a1a1a;
  border-radius: 16px;
  padding: 12px;
  margin: 0 8px;
}

.chat-message.user-message .post-content {
  background: #1da1f2;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 1rem;
  margin: 1rem;
  border-radius: 8px;
  text-align: center;
}

.arrow-indicator {
  margin-top: 20px;
  font-size: 18px;
  color: #888;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.arrow {
  font-size: 24px;
  animation: pointRight 1.5s infinite;
}

/* Add these new styles to your existing App.css */
.welcome-page {
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  background-color: #000; /* Added black background */
  min-height: calc(100vh - 64px);
}

.welcome-header {
  text-align: center;
  margin-bottom: 32px;
  width: 100%;
}

.welcome-title {
  font-size: 24px;
  color: #fff;
  margin-bottom: 12px;
}

.welcome-description {
  color: #888;
  font-size: 15px;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.5;
}

.start-section {
  margin-top: 32px;
  text-align: center;
  animation: fadeIn 0.3s ease;
}

.welcome-page .active-politicians {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.welcome-page .politician-buttons {
  justify-content: center;
  margin-top: 16px;
}

.welcome-page .start-button {
  min-width: 250px;
  height: 68px;
  font-size: 16px;
  padding: 0 32px;
}

.welcome-page .start-section {
  margin-top: -1px; /* Reduced from default margin-top of 32px */
  text-align: center;
  animation: fadeIn 0.3s ease;
}


/* Updated politician grid styles - Add to your App.css */

.politician-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px 32px; /* Reduced gap from original spacing */
  padding: 24px;
  justify-items: center;
  max-width: 800px;
  margin: 0 auto;
}

/* Remove any grid-column rules to ensure side panel is controlled via menu */

.politician-image-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 240px;
  text-align: center;
}

.politician-image-button:hover .politician-image-container {
  transform: translateY(-4px);
}

.politician-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  transition: transform 0.2s ease;
}

.politician-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid transparent;
  transition: all 0.2s ease;
  object-fit: cover;
}

.politician-image-button.selected .politician-avatar {
  border-color: #1da1f2;
  box-shadow: 0 0 0 4px rgba(29, 161, 242, 0.3);
}

.politician-name {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin-top: 8px;
}

/* Large desktop screens */
@media screen and (min-width: 1441px) {
  .politician-grid {
    gap: 40px 32px;
    max-width: 900px;
  }

  .politician-image-button {
    width: 260px;
  }

  .politician-avatar {
    width: 100px;
    height: 100px;
  }

  .politician-name {
    font-size: 22px;
  }

  /* Adjust main-content padding for larger screens if needed */
}

/* Regular desktop screens */
@media screen and (max-width: 1440px) and (min-width: 1025px) {
  .politician-grid {
    gap: 40px 32px;
    max-width: 900px;
  }

  .politician-image-button {
    width: 260px;
  }

  .politician-avatar {
    width: 100px;
    height: 100px;
  }

  .politician-name {
    font-size: 22px;
  }
}

/* Mobile Styles */
@media screen and (max-width: 1024px) {
  .main-content {
    padding-top: 80px; /* Increased padding to prevent overlap */
  }

  .politician-grid {
    gap: 32px 24px;
    padding: 24px;
    max-width: 600px;
  }

  .politician-image-button {
    width: 200px;
  }

  .politician-avatar {
    width: 80px;
    height: 80px;
    border-width: 3px;
  }

  .politician-name {
    font-size: 18px;
  }

  /* Welcome page specific styles */
  .welcome-page .menu-button {
    display: block; /* Ensure menu button is visible on all screen sizes */
  }

  .welcome-page .trending-topics,
  .welcome-page .active-politicians {
    background-color: #111;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 24px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .welcome-page .topic-buttons,
  .welcome-page .politician-buttons {
    justify-content: center;
  }

  .welcome-page .arrow-indicator {
    display: none;
  }

  /* General mobile styles */
  .menu-button {
    display: block; /* Ensure menu button is visible on all screen sizes */
  }

  .close-menu-button {
    display: block; /* Ensure close button is visible on all screen sizes */
  }

  .side-panel {
    transform: translateX(100%);
    width: 280px;
  }

  .side-panel.visible {
    transform: translateX(0);
  }

  .main-content {
    padding-bottom: 80px; /* Adjusted from 100px to match footer height */
  }

  /* Post and chat styles */
  .post {
    padding: 12px;
  }

  .post-actions {
    justify-content: space-around;
  }

  .chat-message {
    max-width: 90%;
  }

  .chat-input-container {
    padding: 12px;
  }

  .chat-input-container.fixed-footer {
    max-width: 600px; /* Adjust based on main-content max-width */
  }

  .start-button {
    padding: 12px 24px;
    font-size: 14px;
    width: 160px;
  }

  .trending-topics,
  .active-politicians {
    padding: 14px;
  }

  .arrow-indicator {
    display: none;
  }
  .welcome-page .start-section {
    margin-top: -20px;
  }
  

  /* Ensure main-content has increased padding-top for mobile */
}

/* Tablets and large mobile */
@media screen and (max-width: 768px) {
  .politician-grid {
    gap: 28px 20px;
    padding: 20px;
    max-width: 500px;
  }

  .politician-image-button {
    width: 180px;
  }

  .politician-avatar {
    width: 80px;
    height: 80px;
  }

  .politician-name {
    font-size: 16px;
  }
  .welcome-page .start-section {
    margin-top: -20px;
  }
}

/* Mobile devices */
@media screen and (max-width: 480px) {
  .politician-grid {
    gap: 24px 16px;
    padding: 16px;
    max-width: 400px;
  }

  .politician-image-button {
    width: 140px;
  }

  .politician-avatar {
    width: 80px;
    height: 80px;
  }

  .politician-name {
    font-size: 15px;
  }
  /* Welcome page styles */
  .welcome-page {
    padding: 16px;
  }

  .welcome-title {
    font-size: 20px;
  }

  .welcome-description {
    font-size: 14px;
  }

  /* Header styles */
  .app-header h1 {
    font-size: 1.25rem;
  }

  .menu-button,
  .close-menu-button {
    font-size: 20px;
  }

  /* Side panel styles */
  .side-panel {
    width: 260px;
  }

  /* Post and text styles */
  .post-name,
  .post-text {
    font-size: 14px;
  }

  .action-button {
    font-size: 13px;
    padding: 6px;
  }

  .chat-message {
    max-width: 95%;
  }

  /* Button styles */
  .politician-button,
  .topic-button {
    padding: 8px 14px;
    font-size: 13px;
  }

  /* Component header styles */
  .trending-topics h2,
  .active-politicians h2 {
    font-size: 16px;
  }

  /* Battle information styles */
  .battle-progress {
    font-size: 16px;
  }

  .battle-intensity {
    font-size: 13px;
  }

  /* Status messages */
  .no-posts-title {
    font-size: 20px;
  }

  .no-posts-text {
    font-size: 14px;
  }

  .selection-guide {
    font-size: 13px;
  }

  /* Input styles */
  .chat-input-container input {
    font-size: 15px;
    padding: 10px;
    max-width: 100%; /* Ensure responsiveness */
  }

  .start-button {
    padding: 10px 20px;
    font-size: 13px;
    width: 140px;
  }

  .selection-error {
    font-size: 13px;
    padding: 8px;
    margin: 8px 0;
  }
  .welcome-page .start-section {
    margin-top: -20px;
  }
  
}

/* Small mobile devices */
@media screen and (max-width: 360px) {
  .politician-grid {
    gap: 20px 12px;
    padding: 12px;
  }

  .politician-image-button {
    width: 120px;
  }

  .politician-avatar {
    width: 80px;
    height: 80px;
    border-width: 2px;
  }

  .politician-name {
    font-size: 14px;
  }
  .welcome-page .start-section {
    margin-top: -20px;
  }
}

/* Animations */
@keyframes pointRight {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Fixed Footer for Chat Mode */
.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.95);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  padding: 16px;
  border-top: 1px solid #444;
  display: flex;
  gap: 8px;
  align-items: center;
  z-index: 1000; /* Ensure it stays above other elements */
  max-width: 800px; /* Ensure it does not exceed chat container width */
  margin: 0 auto; /* Center the input container */
}

.fixed-footer input {
  max-width: 100%; /* Ensure input does not exceed its container */
}

/* Adjust main-content to account for fixed header and footer */
.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 64px; /* Header height */
  min-height: 100vh;
  width: 100%;
  overflow-y: scroll; /* Changed from auto to scroll */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE/Edge */
}

.main-content::-webkit-scrollbar {
  display: none;
}